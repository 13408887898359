// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const index = () => import('./views/index')

// start Routes
export default [
  {
    path: '/camel-trade',
    name: 'camelTrade',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'camelTrade',
      meta: {
        name: 'camelTrade',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.camelTrade',
            active: true
          }
        ]
      },
      component: index
    }
    ]
  }
]
