export default {
  home: 'الرئيسية',
  statistics: 'إحصائيات',
  Permissions: 'التصاريح',
  insurance: 'التأمين',
  transportation: 'النقل',
  veterinary: 'البيطرة',
  events: 'الفعاليات',
  marketplace: 'طلباتي',
  wallet: 'محفظتي',
  favourite: 'مفضلتي',
  comments: 'تعليقاتي',
  settings: 'الإعدادات',
  insuranceDetails: 'تفاصيل التأمين',
  insuranceNow: ' امن الآن',
  transportationNow: 'اطلب رحلة',
  transportationDetails: 'تفاصيل النقل',
  shopping: 'التسوق',
  orders: 'إجمالي الطلبات',
  on_delivery_orders: 'جاري الشحن',
  pending_orders: 'في الانتظار',
  success_orders: 'تم الاستلام',
  searchHere: 'ابحث هنا',
  company: 'الشركة',
  status: 'الحالة',
  lastEnter: 'اخر دخول بتاريخ',
  documented: 'موثق',
  veterinaryDetails: 'تفاصيل البيطره',
  emptyFavourite: 'لا يوجد لديك مفضلة حتى الأن',
  goHomepage: 'توجه الى الصفحة الرئيسية',
  statuses: {
    in_progress: 'قيد التنفيذ',
    shipping_to_order: 'جاري الشحن ',
    rejected: 'مرفوض',
    delivered: 'تم التوصيل',
  }

};
