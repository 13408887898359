export default {
  joiningServices: 'join as seller',
  ownerStoreInfo: 'owner store details',
  storeInfo: 'store details',
  registerJoiningServices: 'New registration to join as a merchant or service provider',
  chooseServices: 'Choose the service you provide',
  continue: 'continue',
  servicesType: 'services type',
  independentDoctor: 'Independent doctor',
  clinic: 'clinic',
  independentDriver: 'independent driver',
  shippingCompany: 'shipping company'
}
