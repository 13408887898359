export default {
  headerTitle: 'Ask for a Veterinarian doctor for your camels',
  headerSubtitle: 'We provide you with certified doctors for the convenience of your camels',
  headerBtn: 'book an appointment',
  headerDetails: 'The IBBIL platform has launched a new initiative for camels’ treatment to take care \n' +
    'of their health by launching a veterinary platform with the IBBIL website, where the \n' +
    'platform enables you to choose from a group of certified doctors who offer their \n' +
    'medical services on IBBIL platform, whether you want a check-up, home visit or \n' +
    'medical consultation. In addition to the possibility of dispensing medication for your \n' +
    'camels and delivering it to your home at the time you prefer.',
  ourServices: 'our services',
  veterinaryServices: 'Veterinary services',
  veterinaryServicesDesc: 'After filling out the form, the price of your camel treatment will appear to you. If you\n' +
    'accept the price, you will be transferred to the payment system through one of the \n' +
    'approved payment methods in the Kingdom of Saudi Arabia. After that, an invoice \n' +
    'for camel treatment and the reservation method that you have chosen will be \n' +
    'issued',
  bookNow: 'Book Now',
  howToBook: 'How to Book',
  howToBookDesc: 'To ensure the convenience of your camels, you can choose the booking method you\n' +
    'prefer through IBBIL platform, whether you prefer a clinic check-up, a home visit, or \n' +
    'a medical consultation. It also enables you to dispense medicines through the \n' +
    'veterinary platform on the IBBIL website. Book now.',
  clinicDetection: 'Clinic check-up',
  HomeVisit: 'Home visit',
  medicalConsultation: 'Medical consultation',
  dispensingMedicines: 'Dispensing medicines',
  city: 'city',
  sort: 'sort',
  filter: 'filter',
  doctor: 'Doctor',
  appointment: 'the appointment',
  reservationPrice: 'Reservation price',
  BookAnAppointment: 'Book an appointment',
  reservationData: 'reservation data',
  bookingConfirmed: 'booking confirmed',
  serviceType: 'service type',
  doctorChoice: 'doctor choice',
  setSpecificTime: 'set specific time',
  confirmReservation: 'confirm reservation',
  mustSelectService: 'You must select the service',
  searchByName: 'Search by the name of the doctor or hospital',
  waitingRoom: 'Fun waiting room',
  utilityBed: 'utility bed',
  chooseReservationDate: 'Choose a reservation date',
  from: 'from',
  to: 'to',
  today: 'today',
  tomorrow: 'tomorrow',
  book: 'Book',
  modifyDay: 'Modify day',
  address: 'address',
  rs: 'SAR',
  receipt: 'receipt',
  bookings: 'bookings'
};
