import authRoutes from './auth/auth.routes';
import joinRoutes from './joining/joining.routes';

// primary Pages
import homeRoutes from './primaryPages/home/home.routes';
import aboutUsRoutes from './primaryPages/aboutUs/aboutUs.routes';
import termsConditionRoutes from './primaryPages/termsCondition/termsCondition.routes';
import privacyPolicyRoutes from './primaryPages/privacyPolicy/privacyPolicy.routes';
import servicesRoutes from './primaryPages/services/services.routes';

import contactUsRoutes from './primaryPages/contactUs/contactUs.routes';
import userProfile from './userProfile/userProfile.routes';

// service Pages

import marketplace from './servicePages/marketplace/marketplace.routes';
import camelTrade from './servicePages/camelTrade/camelTrade.routes';
// search
import productSearchRoutes from './products-search/index.routes';
import invoice from './invoice/invoice.routes';

export default [
  ...authRoutes,
  ...joinRoutes,
  ...homeRoutes,
  ...userProfile,
  ...aboutUsRoutes,
  ...termsConditionRoutes,
  ...privacyPolicyRoutes,
  ...servicesRoutes,
  ...contactUsRoutes,
  ...marketplace,
  ...camelTrade,
  ...productSearchRoutes,
  ...invoice
];
