import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import spinnerLoading from '@/components/spinnerLoading';
import InfiniteLoading from 'vue-infinite-loading';
import vSelect from 'vue-select';
import welcomePage from '@/components/welcomePage';
import Swiper from '@/components/core/slider/Swiper';
import SwiperNav from '@/components/core/slider/SwiperNav';
import SwiperSlide from '@/components/core/slider/SwiperSlide';
import mainTable from '@/components/table/mainTable';
import deletePopup from '@/components/modal/deleteModal';
import DateRangePicker from 'vue2-daterange-picker';
import RoundedModalButton from '@/components/buttons/roundedModalButton';
import cropperImages from '@/components/cropperImages';
import Flickity from 'vue-flickity';
// main modal
import mainModal from '@/components/modal/mainModal';
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

// form Elements
import inputForm from '@/components/formElements/InputForm';
import mainSelect from '@/components/formElements/VSelect';
import flatPickr from 'vue-flatpickr-component';
import SliderThumbs from '@/components/core/slider/sliderThumbs';
import VueCountryCode from 'vue-country-code';
import commingSoon from '@/views/Pages/ComingSoon';
import VueTour from 'vue-tour';
import mainFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
// import lightBox from '@/components/core/slider/lightbox'

import '@morioh/v-lightbox/dist/lightbox.css';
import Lightbox from '@morioh/v-lightbox';

import VueSweetalert2 from 'vue-sweetalert2';

import countryCode from '@/components/formElements/countryCode';

Vue.use(Lightbox);
Vue.use(mainFormWizard);
Vue.use(LottieVuePlayer);
require('vue-tour/dist/vue-tour.css');
Vue.use(VueSweetalert2);
Vue.use(VueTour);
Vue.component('Flickity', Flickity);
Vue.use(VueCountryCode);
Vue.component('commingSoon', commingSoon);
Vue.component('SliderThumbs', SliderThumbs);
Vue.component('multiselect', Multiselect);
Vue.component('DatePicker', DatePicker);
Vue.component('spinner-loading', spinnerLoading);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('v-select', vSelect);
Vue.component('welcome-page', welcomePage);
Vue.component('Swiper', Swiper);
Vue.component('SwiperNav', SwiperNav);
Vue.component('SwiperSlide', SwiperSlide);
Vue.component('DateRangePicker', DateRangePicker);
Vue.component('mainTable', mainTable);
Vue.component('deletePopup', deletePopup);
Vue.component('main-modal', mainModal);
Vue.component('input-form', inputForm);
Vue.component('country-code', countryCode);
Vue.component('main-select', mainSelect);
Vue.component('model-button', RoundedModalButton);
Vue.component('cropper-images', cropperImages);
Vue.component('flat-pickr', flatPickr);
Vue.component('vue-country-code', VueCountryCode);
