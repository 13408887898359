import endUser from './endUser/endUser';
import auth from './auth/auth';
import main from './main/main';
import marketplace from './marketplace/marketplace';
import payment from './marketplace/payment/payment';
import cart from './cart/cart';
import joining from './joining/joining';
import userProfile from './userProfile/userProfile';
import insurance from './insurance/insurance';
import transportation from './transportation/transporation';
import veterinary from './veterinary/veterinary';
import permissions from './permissions/permissions';
import aboutUs from './aboutUs/aboutUs';
import validation from './validation';
export default {
  endUser,
  auth,
  main,
  marketplace,
  payment,
  cart,
  joining,
  userProfile,
  insurance,
  transportation,
  veterinary,
  permissions,
  aboutUs,
  validation
};
