// Layout
const websiteLayout = () => import('@/layouts/websiteLayout');

const userProfile = () => import('./views/index');
// const home = () => import('./views/userMarketplace')
const favourite = () => import('./views/favourite');

const userMarketplace = () => import('./views/userMarketplace');
const userAddress = () => import('./views/userAddress');
const indexWallet = () => import('./views/wallet/index');
const wallet = () => import('./views/wallet/wallet');
const walletSetings = () => import('./views/wallet/settings');
const userSettings = () => import('@/modules/userProfile/views/userSettings');
// start Routes
export default [
  {
    path: '/user-profile',
    name: 'user-profile',
    component: websiteLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'userProfile',
        meta: {
          name: 'userProfile',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: userProfile,
        children: [
          {
            path: 'user-marketplace',
            name: 'user-marketplace',
            meta: {
              name: 'user-marketplace',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userMarketplace
          },
          {
            path: 'favourite',
            name: 'favourite',
            meta: {
              name: 'favourite',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: favourite
          },
          {
            path: 'addresses',
            name: 'addresses',
            meta: {
              name: 'addresses',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userAddress
          },
          {
            path: 'user-settings',
            name: 'userSettings',
            meta: {
              name: 'user-settings',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: userSettings
          },
          {
            path: 'wallet',
            name: 'indexWallet',
            component: indexWallet,
            children: [
              {
                path: '',
                name: 'indexWallet',
                meta: {
                  name: 'indexWallet',
                  userType: '',
                  permission: '',
                  breadCrumb: []
                },
                component: wallet
              },
              {
                path: 'wallet-setings',
                name: 'walletSetings',
                meta: {
                  name: 'walletSetings',
                  userType: '',
                  permission: '',
                  breadCrumb: []
                },
                component: walletSetings
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'payment-success',
    path: '/payment/success',
    component: websiteLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'stripe-success',
        component: () => import('./views/paymentSuccess')
      }
    ]
  }
];
