export default {
  discountCode: 'كود الخصم',
  discountAmount: 'قيمة الخصم',
  clientName: 'اسم العميل',
  country: 'الدولة',
  city: 'المدينة',
  street: 'الشارع',
  district: 'الحى',
  phoneNumber: 'رقم الجوال',
  addressDetails: 'تفاصيل العنوان او علامة مميزة',
  specialSign: 'علامة مميزة',
  cardNumber: 'رقم البطاقة',
  monthEnd: 'شهر الانتهاء',
  yearEnd: 'سنة الانتهاء',
  cvv: 'CVV',
  activate: 'تفعيل',
  diactivate: 'إلغاء',
  addNewCreditCard: 'اضف بطاقة إئتمانية جديدة',
  addNewCredit: 'اضف بطاقة إئتمانية',
  sure: 'تأكيد',
  paymentOptions: 'طرق الدفع',
  myAddress: 'عناويني',
  address: 'العنوان',
  area: 'المنطقة',
  title: 'النوع',
  zipCode: 'الرمز البريدى',
  addNewAddress: 'أضف عنوانا جديدا',
  addAddress: 'إضافة عنوان',
  editAddress: 'تعديل العنوان',
  e_wallet: 'المحفظة الالكترونية',
  WALLET: 'المحفظة',
  CARD: 'البطاقة الائتمانية',
  creditCard: 'البطاقة الائتمانية',
  chooseAddress: 'اختيار العنوان',
  validOrder: ' تأكيد الطلب',
  continueShopping: 'متابعه التسوق',
  orderInfo: 'ملخص الطلب',
  tax: 'ضريبة القيمة المضافة',
  transportation: 'الشحن',
  sum: 'المجموع',
  edit: 'تعديل',
  delete: 'حذف',
  change: 'تغير',
  balance: 'رصيد المحفظة',
  add_balance: 'اضافة رصيد',
  password: 'كلمة السر',
  add_balance_wallet: 'إضافة رصيد للمحفظة',
  total: 'الإجمالى',
  master_card: 'بطاقة ماستر كارد',
  balance_charge: 'الرصيد المطلوب شحنه',
  withdraw_amount: 'الرصيد المطلوب سحبه',
  withdraw: 'سحب رصيد',
  withdraw_request: 'طلب سحب',
  charge: 'شحن',
  setting: 'اعدادات المحفظة',
  pin_code: 'رمز ال PIN',
  current_pin_code: 'رمز ال PIN الحالى',
  new_pin_code: 'ادخل الرمز الجديد',
  confirm_pin_code: 'تأكيد الرمز الجديد',
  forget_pin: 'نسيان رمز ال PIN',
  to_forget: 'لنسيان الرقم السرى للمحفظة',
  successDiscount: 'تم تطبيق كود الخصم بنجاح بقيمة',
};
