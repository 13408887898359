import * as rules from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, } from 'vee-validate';
import Vue from 'vue';
import i18n from '../i18n';

// Install required rule
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[ rule ], // copies rule configuration
    message: i18n.t(`validation.${ rule }`) // assign message
  });
});


extend('numeric', {
  ...rules[ 'numeric' ],
  message: i18n.t(`validation.numeric`)
});



Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

