import { marketPlace, website, singleSignOn } from '@/axios';

export default {
  getAllNotifications () {
    return website().get('/user/notifications');
  },
  getAllOrders (params) {
    return marketPlace().get('/customer/orders', {
      params
    });
  },
  getOrdersStats () {
    return marketPlace().get('/customer/orders/statistics');
  },

  async filterOrders (page, query) {
    let url = `customer/orders?page=${ page }`;
    const keys = Object.keys(query);
    for await (const item of keys) {
      if (query[ item ]) { url = url + `&${ item }=${ query[ item ] }`; }
    }
    url = url.replace('#', '%23');
    return marketPlace().get(url);
  },

  // wallet
  getWallaetDetails () {
    return website().get('customer/wallet/wallet-statics');
  },
  editWalletInfo (payload) {
    return website().patch('user/wallets', payload);
  },
  editPinCode (payload) {
    return website().patch('user/wallets/change-pin', payload);
  },
  forgetPinCode (payload) {
    return website().post('user/wallets/forget-pin', payload);
  },
  createPinCode (payload) {
    return website().post('user/wallets/reset-pin', payload);
  },
  requestWithdraw (payload) {
    return website().post('customer/wallet/withdraw', payload);
  },
  async deposit (payload) {
    return await website().post('/customer/wallet/deposit/urway-web', payload);
  },
  getAllTransactions (params) {
    return website().get(`customer/wallet/wallet-transactions`, {
      params
    });
  },
  paymentSuccess (query) {
    const queries = Object.keys(query).map(key => `${ key }=${ query[ key ] }`).join('&');
    return website().get(`/payment/success?${ queries }`);
  },

  getUserData () {
    return singleSignOn().get('profile/users');
  },
  editUserProfile (payload) {
    return singleSignOn().patch('/customer/profile', payload);
  },
  uploadProfile (payload) {
    return website().post('upload', payload);
  },
  changePassword (payload) {
    return singleSignOn().post('customer/profile/change-password', payload);
  },
  createPassword (payload) {
    return singleSignOn().post('reset-password', payload);
  },
  downloadInvoice (id) {
    return marketPlace().post(`invoice/order/${ id }`);
  }
};
