<template>
  <button class="app-button" :disabled="disabled || loading">
    <slot></slot>
    <div class="spinner-border" role="status" v-if="loading"></div>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-button {
  background-color: var(--iq-primary);
  color: var(--iq-white);
  border-radius: 4px !important;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;

  .spinner-border {
    width: 1rem !important;
    height: 1rem !important;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>