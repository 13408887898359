import { marketPlace } from '@/axios';

export default {
  getAllStoresGategories () {
    return marketPlace().get('customer/product-category');
  },
  getAllStores (params) {
    return marketPlace().get(`customer/stores`, { params });
  },
  getStoreInfo (id) {
    return marketPlace().get(`customer/stores/${ id }`);
  },
  getAllProducts (id, sort = 'DESC', cat = '', page = 1) {
    return marketPlace().get(`customer/stores/${ id }/products?&category_id=${ cat }&page=${ page }`);
  },
  getCategoryStore (id) {
    return marketPlace().get(`${ id }/product-categories`);
  },
  getProductDetail (id) {
    return marketPlace().get(`customer/products/${ id }`);
  },
  getCart () {
    return marketPlace().get('customer/cart/');
  },
  getUpdatedProductsFromServer (payload) {
    return marketPlace().post('carts/without-auth', payload);
  },
  postProductInCart (payload) {
    return marketPlace().post('customer/cart', payload);
  },
  deleteProductInCart (id) {
    return marketPlace().delete(`customer/cart/cart-item/${ id }`);
  },
  addProductWishlist (payload) {
    return marketPlace().post(`/customer/favourite-products/${ payload.product_id }`);
  },
  getProductWishlist () {
    return marketPlace().get('customer/favourite-products');
  },
  deleteProductWishList (id) {
    return marketPlace().delete(`/customer/favourite-products/${ id }`);
  },
  getAddress () {
    return marketPlace().get('/customer/customers-addresses');
  },
  getSpacificAddress (id) {
    return marketPlace().get(`/customer/customers-addresses/${ id }`);
  },
  addAddress (payload) {
    return marketPlace().post('/customer/customers-addresses', payload);
  },
  editAddress (id, payload) {
    return marketPlace().patch(`/customer/customers-addresses/${ id }`, payload);
  },
  deleteAddress (id) {
    return marketPlace().delete(`/customer/customers-addresses/${ id }`);
  },
  payOrder (payload) {
    const { cartId, type, ...rest } = payload;
    const url = type === 'wallet' ? `/customer/orders/checkout/${ cartId }/wallet` : `/customer/orders/checkout/${ cartId }/urway-web`;
    return marketPlace().post(url, rest);
  },

  applyCopoun (payload) {
    return marketPlace().post('customer/cart/apply-coupon', payload);
  },
  removeCopoun (payload) {
    return marketPlace().post('customer/cart/remove-coupon', payload);
  },
  payWithCardInMarketPlace (payload) {
    return marketPlace().post('customer/orders/success-payment', payload);
  },
  deleteStoreFromCart (id) {
    return marketPlace().delete(`/customer/cart/${ id }`);
  }
};
