export default {
  english: 'English',
  arabic: 'Arabic',
  login: 'Login',
  cart: 'shopping cart',
  filter: 'Filter',
  search: 'search',
  save: 'Save',
  country: 'all countries',
  city: 'choose city',
  cities: 'cities',
  area: 'choose area',
  sort: 'sort',
  details: 'details',
  categories: 'categories',
  products: 'products',
  product: 'product',
  getDefault: 'filter',
  searchResult: 'search results',
  noResult: 'no results found',
  marketplace: 'marketplace',
  website: 'website',
  insurance: 'insurance',
  transportation: 'transportation',
  veterinary: 'veterinary',
  stores: 'The stores',
  apothecary_Market: 'Apothecary Market',
  choose_location: 'Choose your location',
  apothecary_Market_brief: 'apothecary Marketplace Platform specializes in all spices related to its delivery to the customer laminated and securely',
  about_market: 'About Apothecary Market',
  important_links: 'Important Links',
  download_app: 'Download App',
  social_accounts: 'Our social media accounts',
  sar: ' sar',
  per: 'per',
  back_store: 'Back to the store',
  all: 'All',
  order_num: 'order number',
  status: 'status',
  pay: 'pay',
  back: 'back',
  link: 'link',
  short_link: 'short link',
  no_data: 'No data available',
};
