import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    cart: [],
    favourite: localStorage.getItem('favourite') ? JSON.parse(localStorage.getItem('favourite')) : []
  },
  getters: getters,
  actions: actions,
  mutations: mutations
};
