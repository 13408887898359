<template>
  <b-modal
    v-model="completeProfile"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    centered
    hide-footer
    hide-header
  >
    <div class="p-3">
      <h5
        class="text-capitalize pb-1 mb-2"
        style="border-bottom: 1px solid #f2f2f2"
      >
        {{ $t("endUser.completeProfile") }}
      </h5>
      <ValidationObserver ref="profile" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(submitProfile)">
          <input-form
            class="mb-3"
            v-model="user.name"
            :validate="'required'"
            :placeholder="$t('auth.userName')"
            :name="$t('auth.userName')"
            :label="$t('auth.userName')"
          />
          <input-form
            v-if="!hasEmail"
            class="mb-3"
            v-model="user.email"
            :placeholder="$t('auth.email')"
            :name="$t('auth.email')"
            :label="$t('auth.email')"
            type="email"
            validate="required|email"
          />
          <country-code
            v-if="!hasPhone"
            class="mb-3"
            v-model="user.phone"
            :label="$t('auth.phoneNumber')"
            :name="$t('auth.phoneNumber')"
            :placeholder="$t('auth.phoneNumber')"
            :validate="'required|numeric|min:9|max:11'"
            @onSelect="onSelect"
          />

          <app-button :loading="loading">{{ $t("auth.save") }} </app-button>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import AppButton from "../buttons/appButton.vue";
import CountryCode from "../formElements/countryCode.vue";
import InputForm from "../formElements/InputForm.vue";
import userProfile from "../../modules/userProfile/services/userProfile";
import { core } from "@/config/pluginInit";
import EventBus from "../../eventBus";

export default {
  components: { InputForm, CountryCode, AppButton },
  data() {
    return {
      completeProfile: false,
      loading: false,
      user: {},
    };
  },
  computed: {
    hasPhone() {
      const { phone } = JSON.parse(localStorage.getItem("userInfo")).user;
      return phone !== null;
    },
    hasEmail() {
      const { email } = JSON.parse(localStorage.getItem("userInfo")).user;
      return email !== null;
    },
  },
  watch: {
    user: {
      handler: function (val) {
        if (val && !val.name) {
          this.completeProfile = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    onSelect(country_code) {
      this.user.country_code = country_code;
    },
    getUserData() {
      const { name, email, phone, country_code } = JSON.parse(
        localStorage.getItem("userInfo")
      ).user;
      this.user = {
        name,
        email,
        phone,
        country_code,
      };
    },
    async submitProfile() {
      this.loading = true;
      const formData = new FormData();
      formData.append("name", this.user.name);
      formData.append("email", this.user.email);
      formData.append("phone", this.user.phone);
      try {
        const { data } = await userProfile.editUserProfile(formData);
        this.completeProfile = false;
        core.showSnackbar("success", data.message);
        localStorage.setItem("userInfo", JSON.stringify({ user: data.data }));
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
  created() {
    this.getUserData();
  },
  mounted() {
    EventBus.$on("auth", (data) => {
      this.user = data;
    });
  },
};
</script>

<style>
</style>