/* import Api from '@/axios' */
import { marketPlace, website } from '@/axios';

export default {
  /*  listDataTabl (url, filter) {
      return Api().get(url, {
        params: filter
      })
    },
    addImage (payload, config) {
      return Api().post('add-image', payload, config)
    },
    removeImage (id, type) {
      return Api().get(`remove-image/${id}/${type}`)
    },
    removeRow (url, rowId) {
      console.log(url, rowId)
      return Api().delete(`${url}/${rowId}`)
    },
    changeStatus (payload) {
      return Api().post('change-status', payload)
    } */
  getAllContries () {
    return marketPlace().get('countries');
  },
  getAllCities (filter = {}) {
    return marketPlace().get('cities', { params: filter });
  },
  getAreas (filter = {}) {
    return marketPlace().get('areas');
  },
  getGlobalSearch (data) {
    return website().get('customer/products/search', {
      params:data
    });
  },
  getAppSettings () {
    return website().get('customer/app-settings');
  }
};
