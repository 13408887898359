export default {
  home: 'home',
  images: 'images',
  videos: 'videos',
  aboutUs: 'about us',
  services: 'services',
  mediaCenter: 'media center',
  contactUs: 'contact us',
  searchInAllDepartments: 'search In All Departments',
  shops: 'shops',
  permissions: 'permissions',
  permissionsInfo: 'Soon, you will obtain permits for camels easily through our platform',
  insurance: 'insurance',
  insuranceInfo: 'Now, insure the transportation of your camels and receive the insurance policy.',
  transport: 'transportation',
  transportInfo: 'Now, transport your camels inside the Kingdom of Saudi Arabia.',
  veterinarian: 'veterinarian',
  veterinaryInfo: 'We provide you with certified doctors for the convenience of your camels.',
  camelTrade: 'camel trade',
  events: 'events',
  statistics: 'statistics',
  InsurancePolicies: 'Insurance policies',
  purchases: 'purchases',
  transportationsProcess: 'transportation',
  merchants: 'merchants',
  productsCount: 'products',
  doctorsCount: 'doctors',
  footer: 'Souq Al-Atara platform is comprehensively specialized in anything related to perfumery while providing a secure and encapsulated service.',
  termsConditions: 'Terms and Conditions',
  aboutStores: 'about stores',
  allStores: 'all stores',
  camelStores: 'camel stores',
  feedStores: 'feed stores',
  camelProductsStores: 'camel products stores',
  animalFoodStores: 'animal food stores',
  camelSuppliesStores: 'camel supplies stores',
  electronicChipStores: 'electronic chip stores',
  aboutPermits: 'about permits',
  getApermit: 'get a permit',
  permissionVerification: 'permission verification',
  aboutInsuranceServices: 'about insurance services',
  getInsurance: 'get insurance',
  aboutMovingCamels: 'about moving camels',
  submitAtransferRequest: 'submit a transfer request',
  aboutVaterinaryServices: 'about vaterinary services',
  clinics: 'clinics',
  theDoctors: 'the doctors',
  appointmentBooking: 'appointment Booking',
  tradingCamels: 'trading camels',
  camelBreeders: 'camel breeders',
  camelSales: 'camel sales',
  camelRentalAndSharing: 'camel rental and sharing',
  aboutEvents: 'about events',
  eventsSchedule: 'events schedule',
  joinAsAmerchant: 'join as a merchant or service provider',
  whoAreYou: 'who are you',
  theEbelPlatform: 'The Ebel platform is a platform specialized in providing all services related to camels in the Kingdom of Saudi Arabia, including insurance, transportation and permit services, feed stores, camel supplies and veterinary services, including insurance, transportation and permit services, feed stores, food and supplies, camel supplies and veterinary services. It is also considered a trading and permit platform, and feed stores. All services related to camels in the Kingdom of Saudi Arabia',
  signIn: 'sign in',
  register: 'Register',
  account: 'Don`t have an account? Create a new account',
  IdNumber: 'ID',
  fullNAme: 'Full Name',
  phoneNUmber: 'Phone Number',
  password: 'Password',
  storeType: 'Store type',
  storeName: 'Store Name',
  storeAddress: 'Store Address',
  news: 'News',
  aboutUsInfo: 'The IBBIL platform is the first platform to provide an integrated commercial market for all services related to camels in the Kingdom of Saudi Arabia, including for all services related to camels in the Kingdom of Saudi Arabia, including insurance, transportation, permit services, food stores, camel supplies and veterinary services, in addition to trading, renting and sharing camels. You can enjoy shopping in many different stores and benefit from all the services provided by the various merchants, doctors and shop owners who offer their services on IBBIL platform.',
  dontHaveAccount: 'dont have account',
  eventAddress: 'event address',
  eventDescription: 'Description of this event is random text',
  seeAll: 'see all',
  userProfile: 'profile',
  continue: 'continue',
  back: 'back',
  rs: 'RSA',
  termsCondition: 'terms and conditions',
  termsConditionContent: 'The apothecary marketplace serves as a trading platform for suppliers, distributors, and buyers, with a modern platform and tools for displaying spices products, trading based on product type, supply and demand, price and quantity presentation, and sales and purchases via a comprehensive electronic platform. The apothecary market also promotes natural heritage by opening inbound and outbound outlets and markets for high-quality, international-standard products. It is founded on providing the most dependable services in the market to meet the needs of customers using the apothecary market, while considering all technical requirements and ensuring the highest quality standards in spice trading.',
  privacyPolicy: 'privacy policy',
  privacyPolicyContent: 'The apothecary marketplace serves as a trading platform for suppliers, distributors, and buyers, with a modern platform and tools for displaying spices products, trading based on product type, supply and demand, price and quantity presentation, and sales and purchases via a comprehensive electronic platform. The apothecary market also promotes natural heritage by opening inbound and outbound outlets and markets for high-quality, international-standard products. It is founded on providing the most dependable services in the market to meet the needs of customers using the apothecary market, while considering all technical requirements and ensuring the highest quality standards in spice trading.',
  reserved: '©All rights reserved to ittarah platform 2022',
  ourServices: 'Our services',
  more: 'more',
  photoGallery: 'Photo Gallery',
  videoGallery: 'Video Gallery',
  balance_withdrawal: 'balance withdrawal',
  top_up: 'top up',
  all_operations: 'All operations',
  operation: 'operation',
  date: 'date',
  paymentMethod: 'payment Method',
  paymentSuccess: 'payment transaction completed successfully',
  amount: 'amount',
  completeProfile: 'complete your profile',
};
