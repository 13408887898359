// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const contactUs = () => import('./views')

// start Routes
export default [
  {
    path: '/contact-us',
    name: 'contactUs',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'contactUs',
      meta: {
        name: 'contactUs',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.contactUs',
            active: true
          }
        ]
      },
      component: contactUs
    }
    ]
  }
]
