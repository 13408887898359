// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const aboutUs = () => import('./views/index')
// start Routes
export default [
  {
    path: '/about-us',
    name: 'aboutUs',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'aboutUs',
      meta: {
        name: 'aboutUs',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.aboutUs',
            active: true
          }
        ]
      },
      component: aboutUs
    }
    ]
  }
]
