export default {
  headerTitle: 'The first platform for obtaining permits in the Kingdom of Saudi Arabia',
  headerSubtitle: 'You will be able to get permits for camels easily through our platform',
  extractNow: 'extract now',
  headerDetails: 'Soon, the IBBIL platform will make it easier for you to obtain your camel permits, \n' +
    'because of the importance of these permits for owning, trading, and transporting \n' +
    'camels, so there must be a service that makes it easier for you to obtain permits, as\n' +
    'the Kingdom of Saudi Arabia did not delay in supporting camels and facilitating all \n' +
    'Its own services, including obtaining permits.',
  whoObtainPermits: 'Who can obtain the permits',
  whoObtainPermitsDesc: 'All camel owners in the Kingdom of Saudi Arabia, will obtain permits easily inside \n' +
    'the Kingdom of Saudi Arabia by following the steps on the IBBIL website.',
  camelOwnershipPermits: 'Camel Ownership Permits',
  camelOwnershipPermitsDesc: 'Soon, you will be able to obtain permits easily to own camels and participate in \n' +
    'competitions through the IBBIL platform.',
  howObtainPermits: 'How to obtain permits?',
  howObtainPermitsDesc: 'If you want to obtain permits within the Kingdom of Saudi Arabia, then you must \n' +
    'join through the “join Now” icon, and then fill out the form, considering filling in all \n' +
    'requests, and thus you have submitted your request to obtain permits.',
  issuingCamelPermits: 'Issuing camel permits',
  issuingCamelPermitsDesc: 'After filling out the form, the price of the permit will appear to you, and if you \n' +
    'accept the price, you will be transferred to one of the payment systems through one\n' +
    'of the approved payment methods in the Kingdom of Saudi Arabia, then the permits\n' +
    'will be issued to you, and you can use them easily after that.'
}
