import Vue from 'vue';
import VueRouter from 'vue-router';
/*
import securityFunctions from '@/mixins/auth-permission'
*/
// Import Business Landing
import endUserRoutes from '@/modules/endUser.routes';
const websiteLayout = () => import('@/layouts/websiteLayout');

/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage');
const paymentStatus = () => import('../views/Pages/payment-status');
/* const ComingSoon = () => import('../views/Pages/ComingSoon')

const Maintenance = () => import('../views/Pages/Maintenance') */

Vue.use(VueRouter);

const routes = [
  ...endUserRoutes,
  {
    path: '/payment-status',
    name: 'paymentStatus',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'paymentStatus',
        meta: {
          name: 'paymentStatus',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: paymentStatus
      }
    ]
  },
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('userToken');
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next({ name: 'auth' });
  }
  else {
    next();
  }
  if (to.matched.some(record => record.path.includes('auth')) && loggedIn) {
    next({ name: "userSettings" });
  }
  else {
    next();
  }


});


export default router;
