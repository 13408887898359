<template>
  <div class="iq-comingsoon py-5">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-9 text-center">
          <div class="iq-comingsoon-info">
            <a href="#">
              <img :src="require('../../assets/images/ibbil/coming-soon.svg')" class="img-fluid width-logo" alt="logo">
            </a>
            <h3 class="mt-4 mb-1">نعمل من أجلكم وقريبا يمكنكم زيارتنا</h3>
            <h3 class="text-black">We work for you, You can visit us shortly</h3>
            <router-link to="/"><b-button variant="primary mt-4 py-2 px-4">{{ $t('userProfile.goHomepage') }}</b-button></router-link>
<!--            <ul class="countdown" id="clockdiv" data-date="Dec 02 2023 20:20:22">
              <li><span data-days>0</span>Days</li>
              <li><span data-hours>0</span>Hours</li>
              <li><span data-minutes>0</span>Minutes</li>
              <li><span data-seconds>0</span>Seconds</li>
            </ul>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
const coundown = () => import('../../assets/js/countdown.min.js')
export default {
  name: 'ComingSoon',
  mounted () {
    coundown()
    core.index()
  }
}
</script>
<style>
.width-logo {
  width: 200px;
}
</style>
