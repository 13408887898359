export default {
  english: 'الأنجليزية',
  arabic: 'العربية',
  login: 'تسجيل الدخول',
  cart: 'سلة المشتريات',
  filter: 'فلتر',
  search: 'بحث',
  save: 'حفظ',
  country: 'اختر الدولة',
  city: 'اختر المدينة',
  cities: 'مدن',
  area: 'اختر المنطقة',
  status: 'الحالة',
  order_num: 'رقم الطلب',
  sort: 'الفرز الافتراضي',
  products: 'المنتجات',
  product: 'منتج',
  getDefault: 'الفرز الافتراضى',
  categories: 'الاقسام',
  searchResult: 'نتائج البحث',
  details: 'التفاصيل',
  noResult: 'لا توجد نتائج بحث',
  marketplace: 'المتجر',
  website: 'الويب سايت',
  insurance: 'التامين',
  transportation: 'النقل',
  veterinary: 'البيطرة',
  stores: 'المتاجر',
  apothecary_Market: 'سوق العطارة',
  choose_location: 'اختر موقعك',
  apothecary_Market_brief: 'منصة سوق العطارة متخصصة بجميع مايتعلق بالعطارة مع توصليها للعميل مغلفة و بشكل آمن',
  about_market: 'حول سوق عطارة',
  important_links: 'روابط مهمة',
  download_app: 'حمل التطبيق',
  social_accounts: 'حساباتنا على مواقع التواصل',
  sar: 'ر.س',
  per: 'لكل',
  back_store: 'العوده الى المتجر',
  all: 'الكل',
  pay: 'دفع',
  back: 'رجوع',
  link: 'الرابط',
  short_link: 'الرابط المختصر',
  no_data: 'لا يوجد بيانات',
};
