// Layout
const websiteLayout = () => import('@/layouts/websiteLayout')

const services = () => import('./views/index')
// start Routes
export default [
  {
    path: '/services',
    name: 'services',
    component: websiteLayout,
    children: [{
      path: '',
      name: 'services',
      meta: {
        name: 'services',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            text: 'endUser.services',
            active: true
          }
        ]
      },
      component: services
    }
    ]
  }
]
