export default {
  home: 'الصفحة الرئيسية',
  aboutUs: 'من نحن',
  services: 'خدماتنا',
  mediaCenter: 'المركز الإعلامى',
  images: 'صور',
  videos: 'فيديوهات',
  contactUs: 'تواصل معنا',
  shops: 'المتاجر',
  permissions: 'التصاريح',
  permissionsInfo: 'قريبا معنا ،ستتمكن من الحصول على التصاريح الخاصة بالإبل بسهولة من خلال منصتنا',
  insurance: 'التأمين',
  insuranceInfo: 'الآن بإمكانك التأمين على نقل إبلك واستلام وثيقة التأمين',
  transport: 'النقل',
  transportInfo: 'الان بإمكانك نقل الابل الخاصة بك داخل المملكة',
  veterinarian: 'البيطره',
  veterinaryInfo: 'نوفر لك أطباء معتمدين لراحة الإبل الخاصة بك',
  camelTrade: 'تداول الأبل',
  events: 'الفعاليات',
  statistics: 'إحصائيات',
  InsurancePolicies: 'وثيقة تأمين',
  purchases: 'عمليات شراء',
  transportationsProcess: 'عمليات النقل',
  merchants: 'عدد التجار',
  productsCount: 'عدد المنتجات',
  doctorsCount: 'عدد الأطباء',
  searchInAllDepartments: 'بحث في جميع الأقسام',
  footer: 'منصة سوق العطارة متخصصة بجميع مايتعلق بالعطارة مع توصليها للعميل مغلفة و بشكل آمن',
  termsConditions: 'الشروط و السياسات',
  seeAll: 'مشاهدة الكل',
  IdNumber: 'رقم الهوية',
  password: 'الرقم السرى',
  phoneNumber: 'رقم الجوال',
  userName: 'اسم المستخدم',
  fullName: 'الاسم بالكامل',
  email: 'البريد الالكترونى',
  otbCode: 'الرمز',
  storeType: 'نوع المتجر',
  storeName: 'اسم المتجر',
  storeAddress: 'عنوان المتجر',
  news: 'الأخبار',
  aboutUsInfo: 'منصة عطارة هي أول منصة تقدم سوق تجاري متكامل لكافة الخدمات المتعلقة بالإبل في المملكة العربية السعودية، وتشمل خدمات التأمين والنقل والتصريح، ومتاجر الأعلاف و الأغذية ومستلزمات الإبل وخدمات البيطرة، بالإضافة إلى تداول وتأجير و مشاركة الإبل. حيث يمكنك الاستمتاع  بالتسوق في العديد من المتاجر و الاستفادة بجميع الخدمات المقدمة من مختلف التجار، الأطباء و أصحاب المتاجر اللذين يعرضون خدماتهم على منصة إبل',
  dontHaveAccount: 'ليس لديك حساب ؟ ',
  eventAddress: 'عنوان الحدث',
  eventDescription: 'وصف للحدث هذا نص عشوائي',
  userProfile: 'ملفي',
  continue: 'التالى',
  back: 'الرجوع',
  rs: 'رس',
  termsCondition: 'الأحكام والشروط',
  termsConditionContent: '\n' +
    ' سوق العطارة يمثل محطة التداول بين الموردين والموزعين والمشترين من خلال منصة وأدوات حديثة لعرض منتجات العطارة، حيث يتم التداول وفقاً لأنواع المنتجات حسب العرض والطلب مع عرض السعر والكمية وعمليات البيع والشراء من خلال منصة إلكترونية شاملة.\n' +
    'كما يعمل سوق العطارة على تنمية التراث الطبيعي وفتح منافذ وأسواق داخلية وخارجية للمنتجات عالية الجودة المطابقة للمواصفات العالمية، حيث ترتكز على تقديم أكثر الخدمات اعتمادية في السوق للوفاء باحتياجات العملاء المستخدمين لسوق العطارة ، مع المراعاة لجميع الشروط الفنية والتقنية والحرص على أعلى معايير الجودة في التعامل مع التداول بالعطارة.\n',
  privacyPolicy: 'سياسة الخصوصية',
  privacyPolicyContent: '\n' +
    ' سوق العطارة يمثل محطة التداول بين الموردين والموزعين والمشترين من خلال منصة وأدوات حديثة لعرض منتجات العطارة، حيث يتم التداول وفقاً لأنواع المنتجات حسب العرض والطلب مع عرض السعر والكمية وعمليات البيع والشراء من خلال منصة إلكترونية شاملة.\n' +
    'كما يعمل سوق العطارة على تنمية التراث الطبيعي وفتح منافذ وأسواق داخلية وخارجية للمنتجات عالية الجودة المطابقة للمواصفات العالمية، حيث ترتكز على تقديم أكثر الخدمات اعتمادية في السوق للوفاء باحتياجات العملاء المستخدمين لسوق العطارة ، مع المراعاة لجميع الشروط الفنية والتقنية والحرص على أعلى معايير الجودة في التعامل مع التداول بالعطارة.\n',
  reserved: '©جميع الحقوق محفوظة لمنصة عطارة',
  ourServices: 'خدماتنا',
  more: 'المزيد',
  photoGallery: 'معرض الصور',
  videoGallery: 'معرض الفديو',
  balance_withdrawal: 'سحب رصيد',
  top_up: 'اشحن رصيد',
  all_operations: 'كل العمليات',
  operation: 'العمليه',
  date: 'التاريخ',
  paymentMethod: 'طريقة الدفع',
  paymentSuccess: 'تمت عملية الدفع بنجاح',
  amount: 'المبلغ',
  completeProfile: 'اكمل بياناتك',
};
