<template>
  <b-form-group :label="label" :label-for="name">
    <validation-provider
      :name="name"
      :rules="validate"
      :bails="false"
      #default="{ errors }"
    >
      <div
        class="country-code d-flex align-items-center justify-content-between"
      >
        <div
          class="country-code-input"
          :class="inMarketPlace ? 'order-label' : ''"
        >
          <input
            :id="id"
            :disabled="disabled"
            :type="type"
            :class="[
              'form-control bg-white',
              { 'is-invalid': errors.length > 0 },
            ]"
            :value="value"
            :placeholder="placeholder"
            @input="$emit('input', $event.target.value)"
          />
        </div>
        <div
          class="country-code-code"
          :class="inMarketPlace ? 'country-code-join' : ''"
        >
          <div class="d-flex flex-column">
            <!-- v-model="code" -->
            <vue-country-code
              @onSelect="onSelect"
              :onlyCountries="['sa', 'eg']"
              :dropdownOptions="{ disabledDialCode: true }"
              :enabledCountryCode="true"
              :defaultCountry="defaultCountry"
              class="testphone"
              :class="inMarketPlace ? 'order-label' : ''"
            />
          </div>
        </div>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
    type: {
      default: "text",
    },
    id: {
      default: "",
      type: String,
    },
    validate: {
      default: "",
      required: false,
    },
    value: {
      default: "",
      type: String,
    },
    placeholder: {
      default: "",
      type: String,
    },
    disabled: {
      default: false,
    },
    defaultCountry: {
      type: String,
      default: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user.country_code ===
          "002"
          ? "eg"
          : "sa"
        : "sa",
    },
  },
  data() {
    return {
      inMarketPlace: false,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.inMarketPlace =
          route.path.includes("user-profile/user-marketplace") ||
          route.path.includes("join/");
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSelect({ name, dialCode }) {
      const countryCode = {
        20: "002",
        966: "0966",
      };
      this.$emit("onSelect", countryCode[dialCode]);
    },
  },
};
</script>

<style lang="scss" scoped>
.country-code {
  gap: 0.5rem;
  .form-control {
    border: none;
    background: transparent !important;
    border-radius: 0 !important;
  }
  .testphone {
    border-radius: 0 !important;
    border: none !important;
    margin: 0;
  }
  .country-code-code {
    background: #f2f2f2;
    border-radius: 4px;
    color: #000 !important;
    width: max-content;
    * {
      box-shadow: none !important;
    }
  }
  .country-code-input {
    background: #f2f2f2;
    border-radius: 4px;
    color: #000 !important;
    flex-grow: 1;
  }
}
.order-label {
  .form-control {
    background: #fff !important;
  }
}
.country-code-join {
  background: #fff !important;
}
</style>
