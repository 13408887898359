// Layout
const websiteLayout = () => import('@/layouts/websiteLayout');
const authLayout = () => import('@/layouts/authLayout');

const auth = () => import('./views/auth');
const otp = () => import('./views/otp');
// start Routes
export default [
  {
    path: '/auth',
    name: 'auth',
    component: websiteLayout,
    children: [
      {
        path: '',
        name: 'auth',
        meta: {
          name: 'auth',
          userType: '',
          permission: '',
          breadCrumb: [
          ]
        },
        component: authLayout,
        children: [
          {
            path: '/',
            name: 'auth',
            meta: {
              name: 'auth',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: auth
          },
          // {
          //   path: 'registration',
          //   name: 'registration',
          //   meta: {
          //     name: 'registration',
          //     userType: '',
          //     permission: '',
          //     breadCrumb: []
          //   },
          //   component: registration
          // },
          {
            path: '/otp',
            name: 'otp',
            props: true,
            meta: {
              name: 'otp',
              userType: '',
              permission: '',
              breadCrumb: []
            },
            component: otp
          },

        ]
      }
    ]
  }
];
