export default {
  IdNumber: 'national_ID',
  password: 'password',
  newPassword: 'new password',
  passwordChecked: 'confirm password',
  currentPassword: 'current password',
  changePassword: 'change password',
  phoneNumber: 'phone number',
  userName: 'user name',
  fullName: 'Full name',
  email: 'E-mail address',
  address: 'address',
  otbCode: 'Code',
  countryCode: 'country code',
  check: 'check',
  enter: 'submit',
  subject: 'subject',
  enterAccount: 'Login',
  createNewAccount: ' Sign up',
  createAccount: ' Create account',
  codeOtb: 'OTP Code',
  otbTitle: 'A message has been sent to your mobile number',
  otbInfo: 'Include the OTP symbol',
  continueOtb: 'Continue',
  create: 'Create',
  logIn: 'sign in ',
  logout: 'sign out',
  forgetPassword: 'forget password ?',
  send: 'send',
  connectWith: 'Connect with us',
  messageText: 'Message Text',
  save: 'save',
  userInformation: 'User Information',
  change: 'change',
  resetPassword: 'Reset your password',
  resetPasswordContent: 'For reset your password we will send you a code to your email address to reset the password',
  aboutUs: 'about us',
  secondary_phone_number: 'secondary phone number',
  joinUsSeller: 'Join as a merchant/trader',
  joinAsSellerParagraph: 'Join the apothecary marketplace and get your online store.',
  back_home: 'Back to home page',
  acceptTerms: 'I agree to the terms and conditions',
  termsConditions: 'Terms and conditions',
};
