export default {
  joiningServices: 'انضم كتاجر',
  ownerStoreInfo: 'بيانات مالك المتجر',
  storeInfo: 'بيانات المتجر',
  registerJoiningServices: 'تسجيل جديد للإنضمام كتاجر أو مقدم خدمة',
  chooseServices: 'قم بإختيار الخدمة التي تقدمها',
  continue: 'التالى',
  servicesType: 'نوع الخدمة',
  independentDoctor: 'دكتور مستقل',
  clinic: 'العيادة',
  independentDriver: 'سائق مستقل',
  shippingCompany: 'شركة شحن'
}
