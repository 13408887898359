export default {
  insurance: 'insurance',
  completeInsuranceData: 'Do you want to complete insurance data ?',
  insureNow: 'insure now',
  termsAndDetails: 'Read terms and details',
  insureDetails: 'The Insurance House, in partnership with the Camel Club, launched the first camel transport insurance policy in the Kingdom of Saudi Arabia. Because of the importance of camels in our history, their connection to our heritage and culture, and the importance of camels’ safety, we had to take the initiative to launch a camel transport insurance policy. Since the era of King Abdulaziz bin Abdul Rahman may God rest his soul, the Kingdom of Saudi Arabia did not hesitate to support the Arab heritage, which characterizes the inhabitants of the Arabian Peninsula',
  firstInsure: 'The first insurance for transporting camels in the Kingdom of Saudi Arabia.',
  nowYouCanInsure: 'Now you can insure the transportation of your camels and receive the insurance policy.',
  InsuranceTargetAudience: 'Insurance Target Audience',
  InsuranceTargetAudienceInfo: 'All camel owners inside the Kingdom of Saudi Arabia can obtain insurance for transporting camels within the Kingdom by filling out the document available on the site.',
  CamelTransportInsurance: 'Camel transport insurance',
  CamelTransportInsuranceInfo: 'You can obtain insurance for transporting your camels within the Kingdom of Saudi Arabia by filling out the document available on the website and making sure to fill out all requests',
  CamelTransportInsuranceDesc: 'After filling out the form, the price of the camel transport insurance document will appear to you. If you accept the price, you will be transferred to the payment system through one of the approved payment methods in the Kingdom of Saudi Arabia. After that, an invoice will be issued for receiving the application, and then the transportation insurance policy.',
  ChooseTheRightInsuranceForYou: 'Choose the right insurance for you',
  ChooseTheRightInsuranceForYouInfo: 'Now you can choose your preferred insurance company to insure your camels at a suitable price for you with a group of the best insurance companies in the Kingdom of Saudi Arabia.',
  howToInsure: 'How to insure?',
  howToInsureInfo: 'If you want to insure your camel transportation from one place to another within the Kingdom of Saudi Arabia, join through the “join Now” icon, and then fill out the form, considering filling all the requests, and thus you have submitted a request for a camel transport insurance policy submitted through IHC Insurance House.',
  howToPay: 'How to Pay',
  howToPayInfo: 'You can easily pay to insure the transportation of your camels through the approved payment methods in the Kingdom of Saudi Arabia, represented by the digital wallet or by credit cards as Mada and Paymob.',
  insureName: 'name of the insurance',
  chipSerialNumber: 'chip / SerialNumber',
  camelValue: 'camel value',
  status: 'status',
  chipNumber: 'chipNumber',
  chipNumberExists: 'chip number exists',
  chipNumberNotExists: 'chip number not exists',
  chipNumberDuplicated: 'chip number duplicated',
  delete: 'delete',
  save: 'save',
  addCamel: 'add camel',
  totalValueInsured: 'total value / insured camel',
  camelTotalValue: 'camel total value',
  insuranceCompany: 'insurance company',
  ChooseInsuranceCompany: 'Choose insurance company',
  amountInsured: 'the amount insured',
  CertificationFees: 'Certification fees',
  coverage: 'coverage',
  insuranceHolder: 'The name of the insurance holder',
  transportationInsuranceCoverage: 'transportation insurance coverage',
  includesAllExpenses: 'includes all expenses',
  termsAndConditions: 'Terms and Conditions',
  valueTax: 'value tax',
  newStart: 'New start',
  dataComplement: 'data complement',
  customerData: 'Customer data',
  quotations: 'Quotations',
  payments: 'Payments',
  shipmentInformation: 'Shipment information',
  paymentCompleted: 'Payment completed successfully',
  thanks: 'Thank you,',
  transferCamel: 'Do you want to do the transfer of camels?',
  clickHere: 'CLick Here',
  insureCompanyInfo: 'Insurance company data',
  insurancePolicy: 'Insurance policy',
  policyNumber: 'Policy number',
  receipt: 'receipt',
  gotToProfile: 'go to profile',
  pricingDetails: 'Pricing details',
  insuredLoss: 'of the sum insured and every loss occurring to any board',
  enduranceValue: 'Endurance value',
  insuredAccount: 'The insured account number (IBAN)',
  agreeAllInsuranceData: 'I agree that all data in this insurance application are correct and that the attached documents are valid documents.',
  agreeToAll: 'I agree to all',
  idHolder: 'Document holder ID',
  discountQuantity: 'discount quantity',
  transportationCoverage: 'Transportation insurance coverage',
  rs: 'rs',
  total: 'total',
  choose: 'choose',
  previous: 'previous',
  continue: 'continue',
  from: 'from',
  to: 'to',
  bookingDate: 'booking date',
  insurancePeriod: 'insurance period'
}
