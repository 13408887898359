import { core } from '@/config/pluginInit';
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace';

export default {

  async getCart ({ commit }) {
    if (!localStorage.getItem('userToken')) {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
      return commit('SET_CART', cart);
    };
    try {
      const res = await marketPlace.getCart();
      commit('SET_CART', res.data.data);
    }
    catch (err) {
      console.log((err));
    }
  },
  async addToCart ({ commit }, payload) {
    const productCart = {
      product_id: payload.id,
      quantity: payload.quantity
    };
    if (localStorage.getItem('userToken')) {
      try {
        const res = await marketPlace.postProductInCart(productCart);
        core.showSnackbar('success', res.data.message);
        commit('SET_CART', res.data.data);
        return res;
      }
      catch (err) {
        console.log((err));
      }
    } else {
      commit('SET_GUEST_CART', payload);
    }
  },
  async updateCart ({ commit, dispatch }, payload) {
    if (!localStorage.getItem('userToken')) {
      return commit('UPDATE_LOCAL_CART', payload);
    }
    return dispatch('addToCart', payload);
  },
  async deleteProductInCart ({ commit, dispatch }, payload) {
    if (!localStorage.getItem('userToken')) {
      return commit('DELETE_PRODUCT', payload);
    }
    try {
      const res = await marketPlace.deleteProductInCart(payload.productInCartId);
      core.showSnackbar('success', res.data.message);
      dispatch('getCart');
      return res;
    }
    catch (err) {
      console.log((err));
    }
  },
  async deleteStoreFromCart ({ commit }, id) {
    if (!localStorage.getItem('userToken')) {
      return commit('DELETE_STORE', id);
    }
    try {
      const res = await marketPlace.deleteStoreFromCart(id);
      core.showSnackbar('success', res.data.message);
      commit('DELETE_STORE', id);
      return res;
    }
    catch (err) {
      console.log((err));
    }
  },
  async applyCopoun ({ commit }, payload) {
    try {
      const res = await marketPlace.applyCopoun(payload);
      core.showSnackbar('success', res.data.message);
      commit('UPDATE_CART', res.data.data);
      return res;
    }
    catch (err) {
      throw err;
    }
  },
  setFavourite ({ state, commit }, payload) {
    state.favourite = payload;
  },
  addToFavourite ({ state, commit }, payload) {
    const ifProdInFav = state.favourite.findIndex(product => {
      return product.id === payload.id;
    });
    if (ifProdInFav > -1) {
      console.log('remove');
    } else {
      state.favourite.push(payload);
      commit('changeFavouriteInLocalStorage');
      core.showSnackbar('success', 'تم اضافة المنتج فى المفضلة');
    }
  },
  deleteProductInFavourite ({ state, commit }, payload) {
    const ifProdInFav = state.favourite.findIndex(product => {
      return product.id === payload.id;
    });
    if (ifProdInFav > -1) {
      state.favourite.splice(ifProdInFav, 1);
      commit('changeFavouriteInLocalStorage');
      core.showSnackbar('success', 'تم ازالة المنتج من المفضلة');
    }
  }
};
