import website from "@/services/main.js";

export default {
  activePageAction (context, payload) {
    context.commit('activePageCommit', payload);
  },
  setLangAction (context, payload) {
    context.commit('setLangCommit', payload);
  },
  layoutModeAction (context, payload) {
    // const rtl = payload.rtl ? 'rtl' : 'ltr'
    document.getElementsByTagName('html')[ 0 ].setAttribute('dir', localStorage.getItem('lang') === 'en' ? 'ltr' : 'rtl');
    document.getElementsByTagName('html')[ 0 ].setAttribute('lang', localStorage.getItem('lang') === 'en' ? 'en' : 'ar');
    context.commit('layoutModeCommit', {
      rtl: payload.rtl,
      sidebar: ''
    });
  },
  async getAppSettings ({ commit }) {
    try {
      const res = await website.getAppSettings();
      commit('setAppSettings', res.data.data);
    }
    catch (err) {
      console.log(err);
    }
  }
};
