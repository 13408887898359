export default {
  ibbilMarketPlaces: 'متاجر منصة إبل',
  categories: 'الاقسام',
  stores: 'المتاجر',
  products: 'المنتجات',
  LatestStores: 'أحدث المتاجر',
  store: 'المتجر',
  aboutStores: 'عن المتاجر',
  aboutStore: 'عن المتجر',
  allStores: 'كل المتاجر',
  camelStores: 'متاجر الإبل',
  feedStores: 'متاجر الإعلاف',
  camelProductsStores: 'متاجر منتجات الإبل',
  animalFoodStores: 'متاجر الأغذية الحيوانية',
  camelSuppliesStores: 'متاجر مستلزمات الإبل',
  electronicChipStores: 'متاجر الشريحة الإلكترونية',
  driveTo: 'التوصيل ل',
  TermsAndConditions: 'الشروط والسياسات',
  seeMore: 'عرض المزيد',
  addToCart: 'اضافة الى السلة',
  inCart: 'يوجد فى السلة',
  addToFavourite: 'اضافة الى للمفضلة',
  backToProfile: 'الرجوع',
  quantity: 'الكمية',
  savedPrice: 'وفرت',
  beforePrice: 'قبل',
  discount: 'خصم',
  discountCode: 'كود الخصم',
  price: 'السعر',
  priceNow: 'السعر الأن',
  total: 'الاجمالى',
  Specifications: 'المواصفات',
  clientsComments: 'تقييمات العملاء',
  seeAll: 'مشاهدة الكل',
  mayYouLike: 'قد يعجبك أيضا',
  all: 'الكل',
  validProductRate: 'تقييم موثق للمنتج',
  userProductComments: 'تعليقات المشتريين للمنتج',
  validProductPayment: 'عملية شراء موثقة',
  commentedAt: 'تم التعليق فى',
  saveToFavourite: 'اضافة للمفضلة',
  removeFromFavourite: 'ازالة من للمفضلة',
  backToStore: 'عودة للمتجر',
  continuePayment: 'متابعة الشراء',
  mainQuestions: 'الأسئلة الشائعة',
  marketplaceInfo: 'سوق العطارة يمثل محطة التداول بين الموردين والموزعين والمشترين من خلال منصة وأدوات حديثة لعرض منتجات العطارة، حيث يتم التداول وفقاً لأنواع المنتجات حسب العرض والطلب مع عرض السعر والكمية وعمليات البيع والشراء من خلال منصة إلكترونية شاملة.' + 'كما يعمل سوق العطارة على تنمية التراث الطبيعي وفتح منافذ وأسواق داخلية وخارجية للمنتجات عالية الجودة المطابقة للمواصفات العالمية، حيث ترتكز على تقديم أكثر الخدمات اعتمادية في السوق للوفاء باحتياجات العملاء المستخدمين لسوق العطارة ، مع المراعاة لجميع الشروط الفنية والتقنية والحرص على أعلى معايير الجودة في التعامل مع التداول بالعطارة.',
  orderNumber: 'رقم الطلب',
  orderDate: 'تاريخ الطلب',
  totalCostOfOrder: 'إجمالي تكلفة الطلب',
  rs: 'رس',
  storeName: 'اسم المتجر',
  productName: 'اسم المنتج',
  showProduct: 'عرض المنتج',
  printReceipt: 'طباعة الفاتوره',
  shaping: 'الشحن',
  tax: 'ضريبة القيمة المضافة',
  totalPrice: 'المجموع'
}
