<template>
  <div class="position-relative">
    <section ref="allImages" class="allImages">
      <b-row class="flex-md-row flex-column-reverse">
<!--        <b-col md="3" class="px-4">-->
<!--          <div class="swiper-container gallery-thumbs">-->
<!--            &lt;!&ndash;            <div class="swiper-button-next-thumb d-flex justify-content-center align-items-center">&ndash;&gt;-->
<!--            &lt;!&ndash;              <i class="las la-angle-right"></i>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="swiper-button-prev-thumb d-flex justify-content-center align-items-center">&ndash;&gt;-->
<!--            &lt;!&ndash;              <i class="las la-angle-left"></i>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div class="swiper-wrapper">-->
<!--              <div class="swiper-slide" v-for="(image, key) in images" :key="key">-->
<!--                <div class="swiper-slide-container image_slider_background" :style="{  'background-image':'url(' + image + ')' }">-->
<!--                  &lt;!&ndash;                  <img-->
<!--                      :alt="image.name"-->
<!--                      :src="image.image"-->
<!--                      class="img-fluid w  -100 mx-auto iq-border-radius-5"-->
<!--                  />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-col>-->
        <b-col cols="12">
          <div class="swiper-container gallery-top mb-3 w-100">
            <div class="swiper-wrapper w-100">
              <div class="swiper-slide w-100" v-for="(image, key) in images" :key="key">
                <div class="swiper-slide-container w-100">
                  <div
                      class="swiper-slide-container image_slider_background_large"
                      :style="{
                    'background-image':
                      'url(' + image + ')',
                  }"
                  ></div>
                  <!--                  <img-->
                  <!--                      :src="image"-->
                  <!--                      class="img-fluid w-100 mx-auto iq-border-radius-5"-->
                  <!--                      :alt="image"-->
                  <!--                  />-->
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
    <section class="loading-images position-absolute w-100 h-170px bg-white top-0 left-0" v-if="loading">
      <spinner-loading text="loading Images" />
    </section>
  </div>
</template>
<script>
import Swiper, {
  Navigation,
  Pagination,
  Parallax,
  Autoplay,
  Thumbs,
  Controller
} from 'swiper'
Swiper.use([Navigation, Pagination, Parallax, Autoplay, Thumbs, Controller])
let galleryTop, galleryThumbs
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  methods: {
    init () {
      galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: true,
        slidesPerView: 1,
        centeredSlides: false
      })
      galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 15,
        centeredSlides: false,
        slidesPerView: 1,
        slideToClickedSlide: true,
        loop: true,
        loopedSlides: 1
      })
      galleryTop.controller.control = galleryThumbs
      galleryThumbs.controller.control = galleryTop
    }
    /*  replaceByDefault (e) {
        e.target.src = this.defaultImage
      } */
  },
  data () {
    return {
      /* defaultImage: require('@/assets/images/curva/product.png'), */
      loading: true
    }
  },
  mounted () {
    this.loading = true
    setTimeout(() => {
      galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: false,
        slidesPerView: 1,
        initial: 1,
        centeredSlides: true
      })
      galleryThumbs = new Swiper('.gallery-thumbs', {
        direction: 'vertical',
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 3,
        breakpoints: {
          0: {
            direction: 'horizontal'
            // slidesPerView: 1,
            // spaceBetween: 15
          },
          768: {
            direction: 'vertical'
          }
        },
        slideToClickedSlide: true,
        loop: false,
        initial: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
      galleryTop.controller.control = galleryThumbs
      galleryThumbs.controller.control = galleryTop
      this.loading = false
    }, 100)
  }
}
</script>
<style>
.gallery-thumbs {
  height: 300px;
}
/*.swiper-button-prev {
  position: absolute;
  top: 0;
  background: rgb(113 105 105 / 48%);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #e6dede;
  font-weight: bold;
  cursor: pointer;
}*/
.swiper-button-prev-thumb {
  position: absolute;
  top: 0;
  background: rgb(43 88 66);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
}
/*.swiper-button-next {
  position: absolute;
  bottom: 0;
  background: rgb(113 105 105 / 48%);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: var(--iq-warning);
  font-weight: bold;
  cursor: pointer;
}*/
.swiper-button-next-thumb {
  position: absolute;
  bottom: 0;
  background: rgb(43 88 66);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
}
.image_slider_background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.gallery-thumbs .swiper-slide-active {
  box-shadow: 0px 0px 0px 3px var(--iq-warning);
  border-radius: 10px;
  position: relative;
}
.swiper-container-pointer-events.swiper-container-vertical {
  touch-action: pan-x;
  overflow: inherit;
  overflow-y: clip;
}
.gallery-thumbs .swiper-slide-active::after {
  content: "";
  position: absolute;
  border: 3px;
  border-color: var(--iq-warning) transparent;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--iq-warning);
  width: 0;
  top: 37%;
  height: 0;
  left: -10px;
  transform: rotate(180deg);
}
@media only screen and (max-width: 500px) {
  .gallery-thumbs .swiper-slide-active::after {
    transform: rotate(-90deg);
    top: -15px;
    left: 47%;
  }
  .swiper-button-prev-thumb {
    top: 0;
    left: 0;
    height: 100%;
    width: 20px;
  }
  .swiper-button-next-thumb {
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
  }
  .gallery-thumbs {
    height: 110px;
    padding: 10px;
  }
}
</style>
<style>
.gallery-thumbs {
  height: 300px;
}
/*.swiper-button-prev {
  position: absolute;
  top: 0;
  background: rgb(113 105 105 / 48%);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #e6dede;
  font-weight: bold;
  cursor: pointer;
}*/
.swiper-button-prev-thumb {
  position: absolute;
  top: 0;
  background: rgb(43 88 66);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
}
/*.swiper-button-next {
  position: absolute;
  bottom: 0;
  background: rgb(113 105 105 / 48%);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: var(--iq-warning);
  font-weight: bold;
  cursor: pointer;
}*/
.swiper-button-next-thumb {
  position: absolute;
  bottom: 0;
  background: rgb(43 88 66);
  width: 100%;
  text-align: center;
  z-index: 2;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  padding: 3px;
}
.image_slider_background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.gallery-thumbs .swiper-slide-active {
  box-shadow: 0px 0px 0px 3px var(--iq-warning);
  border-radius: 10px;
  position: relative;
}
.swiper-container-pointer-events.swiper-container-vertical {
  touch-action: pan-x;
  overflow: inherit;
  overflow-y: clip;
}
.gallery-thumbs .swiper-slide-active::after {
  content: "";
  position: absolute;
  border: 3px;
  border-color: var(--iq-warning) transparent;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--iq-warning);
  width: 0;
  top: 37%;
  height: 0;
  left: -10px;
  transform: rotate(180deg);
}
@media only screen and (max-width: 500px) {
  .gallery-thumbs .swiper-slide-active::after {
    transform: rotate(-90deg);
    top: -15px;
    left: 47%;
  }
  .swiper-button-prev-thumb {
    top: 0;
    left: 0;
    height: 100%;
    width: 20px;
  }
  .swiper-button-next-thumb {
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
  }
  .gallery-thumbs {
    height: 110px;
    padding: 10px;
  }
}
.image_slider_background_large {
  height: 400px;
  width: 100%;
  background-size: cover;
  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center;
}
.gallery-top{
  /*box-shadow: 0 0 12px #0000001c;*/
  border-radius: 4px;
}
</style>
