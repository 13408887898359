/* import CommonApi from '@/services/common/index' */
export default {
  data () {
    return {
      recentSearch: localStorage.getItem('searchedItems') ? JSON.parse(localStorage.getItem('searchedItems')) : []
    }
  },
  methods: {
    saveBeforeRoute (name) {
      localStorage.setItem('lastRoute', name)
    },
    getLastRoute () {
      return localStorage.getItem('lastRoute')
    },
    checkIfLogin () {
      return localStorage.getItem('userToken') || false
    },
    logout () {
      localStorage.clear()
      this.$router.push({ name: 'index' })
      window.location.reload()
    },
    searchList (item) {
      // get search items from localstorage
      const getSearchedItems = localStorage.getItem('searchedItems')
      if (getSearchedItems) {
        const parsing = JSON.parse(getSearchedItems)
        const findItem = parsing.find(i => parseInt(i.id) === parseInt(item.id) && i.table === item.table)
        if (!findItem) {
          this.recentSearch.unshift(item)
          localStorage.setItem('searchedItems', JSON.stringify([item, ...parsing]))
        }
      } else {
        localStorage.setItem('searchedItems', JSON.stringify([item]))
      }
      this.$router.push(`/${item.type === 'video' ? 'videos' : item.type}/${item.id}`)
    }
    /*    commonUploadFiles (payload) {
      return new Promise((resolve, reject) => {
        CommonApi.commonUploadFiles(payload)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },
    deleteFiles (id) {
      return new Promise((resolve, reject) => {
        CommonApi.deleteFiles(id)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    } */
  }
}
