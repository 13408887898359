// Layout
const websiteLayout = () => import('@/layouts/websiteLayout');

const productsSearch = () => import('./views');

// start Routes
export default [
  {
    path: '/products-search',
    name: 'products-search',
    component: websiteLayout,
    children: [ {
      path: '',
      name: 'products-search',
      meta: {
        name: 'products-search',
        userType: '',
        permission: '',
        breadCrumb: [
          {
            active: true
          }
        ]
      },
      component: productsSearch
    }
    ]
  }
];
